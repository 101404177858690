import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const GET_PAYMENTS = "[PAYMENTS REPORT] GET_PAYMENTS";
export const GET_PAYMENTS_ERROR = "[PAYMENTS REPORT] GET_PAYMENTS_ERROR";
export const GET_PAYMENTS_SUCCESS = "[PAYMENTS REPORT] GET_PAYMENTS_SUCCESS";

export const SET_VALUE = "[PAYMENTS REPORT] SET_VALUE";
export const CLEAR_VALUES = "[PAYMENTS REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getPayments(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_PAYMENTS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/commission/payment?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_PAYMENTS_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_PAYMENTS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_PAYMENTS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const CHANGE_STATUS_COMMISSION = "[USER EDIT] CHANGE_STATUS_COMMISSION";
export const CHANGE_STATUS_COMMISSION_ERROR =
	"[USER EDIT] CHANGE_STATUS_COMMISSION_ERROR";
export const CHANGE_STATUS_COMMISSION_SUCCESS =
	"[USER EDIT] CHANGE_STATUS_COMMISSION_SUCCESS";

export function changeCommissionStatus(id, paymentDate, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CHANGE_STATUS_COMMISSION
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(
				`/commission/user/${id}/paymentDate/${paymentDate}`,
				null,
				{
					Authorization: "Bearer " + login.token
				}
			);

			if (!response.ok) {
				console.log(await response.text());
			}
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CHANGE_STATUS_COMMISSION_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: CHANGE_STATUS_COMMISSION_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			console.log(error);
			return dispatch({
				type: CHANGE_STATUS_COMMISSION_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
