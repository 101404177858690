import React from "react";
import { reduxForm } from "redux-form";
import { Button, ButtonToolbar, Container, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const StatusPaymentForm = ({ handleSubmit, closeModal, initialValues }) => {
	const { paymentDate } = initialValues;
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={6} md={12} className="form__form-group">
						<span className="form__form-group-label">
							Deseja realmente pagar as comissões do dia:
						</span>
						<strong style={{ textAlign: "center" }}>
							{dayjs(paymentDate).format("DD/MM/YYYY")}
						</strong>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="success" type="submit">
					Pagar
				</Button>
				<Button type="button" color="cancel" onClick={closeModal}>
					Cancelar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "update_status"
	// validate
})(StatusPaymentForm);

StatusPaymentForm.propTypes = {
	handleSubmit: PropTypes.func,
	closeModal: PropTypes.func
};
