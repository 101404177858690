import React from "react";
import { Field, reduxForm } from "redux-form";

import EyeIcon from "mdi-react/EyeIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import EmailIcon from "mdi-react/EmailIcon";
import BankIcon from "mdi-react/BankIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import TicketPercentIcon from "mdi-react/TicketPercentIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import AccountCardDetailsIcon from "mdi-react/AccountCardDetailsIcon";
import CreditCardIcon from "mdi-react/CreditCardIcon";
import ViewListIcon from "mdi-react/ViewListIcon";

import validate from "./validate";

import Input from "../../../components/ReduxForm/Input";
import { useState } from "react";
import { PHONE } from "components/NumberFormat";
import { CPF } from "components/NumberFormat";
import { Col, Row, Spinner } from "reactstrap";
import renderSelectField from "shared/components/form/Select";
import renderCheckBoxField from "shared/components/form/CheckBox";

function RegisterForm({ handleSubmit, loading, ...props }) {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<form className="form" onSubmit={handleSubmit}>
			<Row>
				<Col>
					<div className="form__form-group">
						<span className="form__form-group-label">Nome</span>
						<div className="form__form-group-field">
							<div className="form__form-group-icon">
								<AccountOutlineIcon />
							</div>
							<Field
								name="firstName"
								component={Input}
								placeholder="Seu nome"
							/>
						</div>
					</div>
				</Col>
				<Col>
					<div className="form__form-group">
						<span className="form__form-group-label">Sobrenome</span>
						<div className="form__form-group-field">
							<div className="form__form-group-icon">
								<AccountOutlineIcon />
							</div>
							<Field
								name="lastName"
								component={Input}
								placeholder="Seu sobrenome"
							/>
						</div>
					</div>
				</Col>
			</Row>

			<Row>
				<Col>
					<div className="form__form-group">
						<span className="form__form-group-label">CPF</span>
						<div className="form__form-group-field">
							<div className="form__form-group-icon">
								<AccountCardDetailsIcon />
							</div>
							<Field name="document" component={CPF} placeholder="Seu CPF" />
						</div>
					</div>
				</Col>
				<Col>
					<div className="form__form-group">
						<span className="form__form-group-label">Celular</span>
						<div className="form__form-group-field">
							<div className="form__form-group-icon">
								<PhoneIcon />
							</div>
							<Field
								name="phoneNumber"
								component={PHONE}
								placeholder="Nº do seu celular"
							/>
						</div>
					</div>
				</Col>
			</Row>
			<div className="form__form-group">
				<span className="form__form-group-label">E-mail </span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<EmailIcon />
					</div>
					<Field
						name="email"
						component={Input}
						type="email-address"
						placeholder="Seu e-mail"
					/>
				</div>
				<div
					style={{
						marginTop: 6
					}}
				>
					<Field
						name="receiveEmails"
						component={renderCheckBoxField}
						defaultChecked
						label="Desejo receber e-mails de novas comissões"
					/>
				</div>
			</div>

			<div className="form__form-group">
				<span className="form__form-group-label">Código do seu cupom</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<TicketPercentIcon />
					</div>
					<Field
						name="coupon"
						component={Input}
						style={{ textTransform: "uppercase" }}
						type="text"
						placeholder="Ex.: USER10OFF"
					/>
				</div>
			</div>

			<Row style={{ width: "120%" }}>
				<Col xs={6}>
					<div className="form__form-group">
						<span className="form__form-group-label">Banco</span>
						<div className="form__form-group-field">
							<div className="form__form-group-icon">
								<BankIcon />
							</div>
							<Field
								name="bank"
								placeholder="Selecione"
								component={renderSelectField}
								options={props.bankCodes || []}
							/>
						</div>
					</div>
				</Col>
				<Col xs={6}>
					<div className="form__form-group">
						<span className="form__form-group-label">Agência</span>
						<div className="form__form-group-field">
							<div className="form__form-group-icon">
								<CreditCardIcon />
							</div>
							<Field
								name="agency"
								component={Input}
								type="text"
								placeholder="Somente números"
							/>
						</div>
					</div>
				</Col>
			</Row>

			<Row style={{ width: "120%" }}>
				<Col xs={6}>
					<div className="form__form-group">
						<span className="form__form-group-label">Número Conta</span>
						<div className="form__form-group-field">
							<div className="form__form-group-icon">
								<CreditCardIcon />
							</div>
							<Field
								name="account"
								component={Input}
								type="text"
								placeholder="Somente números"
							/>
						</div>
					</div>
				</Col>
				<Col xs={6}>
					<div className="form__form-group">
						<span className="form__form-group-label">Tipo da conta</span>
						<div className="form__form-group-field">
							<div className="form__form-group-icon">
								<ViewListIcon />
							</div>
							<Field
								name="type"
								placeholder="Selecione"
								component={renderSelectField}
								options={props.accountTypes || []}
							/>
						</div>
					</div>
				</Col>
			</Row>
			<div className="form__form-group">
				<span className="form__form-group-label">Senha</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<KeyVariantIcon />
					</div>
					<Field
						name="password"
						component={Input}
						type={showPassword ? "text" : "password"}
						placeholder="Sua senha"
					/>
					<button
						className={`form__form-group-button${
							showPassword ? " active" : ""
						}`}
						onClick={() => setShowPassword(c => !c)}
						type="button"
					>
						<EyeIcon />
					</button>
				</div>
			</div>
			<div className="form__form-group">
				<span className="form__form-group-label">Confirme a senha</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<KeyVariantIcon />
					</div>
					<Field
						name="confirmPassword"
						component={Input}
						type={showPassword ? "text" : "password"}
						placeholder="Confirme sua senha"
					/>
					<button
						className={`form__form-group-button${
							showPassword ? " active" : ""
						}`}
						onClick={() => setShowPassword(c => !c)}
						type="button"
					>
						<EyeIcon />
					</button>
				</div>
			</div>

			<button
				className="btn btn-primary account__btn account__btn--small"
				type="submit"
			>
				{loading ? (
					<Spinner type="grow" size="sm" color="dark" />
				) : (
					"Criar conta"
				)}
			</button>
		</form>
	);
}

export default reduxForm({
	form: "register_form",
	validate
})(RegisterForm);
