import Banks from "assets/banks.json";
import ls from "Localization";
import accountType from "models/accountType";
import React from "react";
import { Card, Col, Container, Row } from "reactstrap";

const UserBankAccountDetail = ({ bankAccount }) => {
	const { name } = Banks.find(e => e.code == bankAccount.bank);

	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Banco: </strong>
										<br />
										<span>{name ? name : "Não definido"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Banco: </strong>
										<br />
										<span>{ls[accountType[bankAccount.type]]}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Agência: </strong>
										<br />
										<span>
											{bankAccount.agency ? bankAccount.agency : "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Conta: </strong>
										<br />
										<span>
											{bankAccount.account
												? bankAccount.account
												: "Não definido"}
										</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default UserBankAccountDetail;
