import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const GET_COMMISSION = "[COMMISSION EDIT] GET_COMMISSION";
export const GET_COMMISSION_ERROR = "[COMMISSION EDIT] GET_COMMISSION_ERROR";
export const GET_COMMISSION_SUCCESS =
	"[COMMISSION EDIT] GET_COMMISSION_SUCCESS";

export const EDIT_COMMISSION = "[COMMISSION EDIT] EDIT_COMMISSION";
export const EDIT_COMMISSION_ERROR = "[COMMISSION EDIT] EDIT_COMMISSION_ERROR";
export const EDIT_COMMISSION_SUCCESS =
	"[COMMISSION EDIT] EDIT_COMMISSION_SUCCESS";

export const SET_VALUE = "[COMMISSION EDIT] SET_VALUE";
export const CLEAR_VALUES = "[COMMISSION EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getCommission(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_COMMISSION
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/commission/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_COMMISSION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_COMMISSION_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_COMMISSION_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}


export function editCommission(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_COMMISSION
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/commission/${id}`,model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_COMMISSION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: EDIT_COMMISSION_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: EDIT_COMMISSION_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}