import React from "react";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import { SubmissionError } from "redux-form";
import { useDispatch } from "react-redux";
import { submitRecoverPassword } from "redux/actions/login";

const LogIn = ({ history }) => {
	const dispatch = useDispatch();
	const onSubmit = data => {
		dispatch(
			submitRecoverPassword(data.email, err => {
				if (err) {
					throw new SubmissionError(err);
				} else {
					history.push("/Login");
				}
			})
		);
	};

	return (
		<div className="account">
			<div className="account__wrapper">
				<div className="account__card">
					<div className="account__head">
						<div className="account__logo" />
						<div className="flex-center">
							<h3 className="account__title">Programa de afiliados</h3>

							<h4 className="account__subhead subhead">
								Informe seu e-mail para recuperar a senha
							</h4>
						</div>
					</div>
					<ForgotPasswordForm onSubmit={onSubmit} />
				</div>
			</div>
		</div>
	);
};

export default LogIn;
