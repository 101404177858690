import React from "react";
import { Col, Container, Row } from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import dayjs from "dayjs";
import ls from "Localization";
import userStatus from "models/userStatus";
import { CPF } from "components/NumberFormat";

let fetchDataDebounced = null;

function Report({ history }) {
	const dispatch = useDispatch();

	const state = useSelector(s => s.user);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];
		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getUsers(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						title="Usuários"
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						onRowClicked={row => history.push(`/user/` + row._id)}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={[
							{
								Header: "Data criação",
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateTimeFormat)
										: "",
								width: 120
							},
							{
								Header: "Nome",
								accessor: "fullName"
							},
							{
								Header: "E-mail",
								accessor: "email",
								width: 200
							},
							{
								Header: "CPF",
								id: "document",
								accessor: c =>
									c.document ? (
										<CPF displayType="text" value={c.document} />
									) : (
										"Não definido"
									),
								width: 120
							},
							{
								Header: "Cupom",
								id: "coupon",
								accessor: c => (c.coupon ? c.coupon.code : "Não definido"),
								width: 180
							},
							{
								Header: "Celular",
								id: "phoneNumber",
								accessor: c =>
									c.nationalPhoneNumber
										? c.nationalPhoneNumber
										: "Não definido",
								width: 120
							},
							{
								Header: "Status",
								id: "status",
								accessor: c => ls[userStatus[c.status]],
								width: 100,
								Filter: ({ filter, onChange }) => (
									<select
										onChange={event => onChange(event.target.value)}
										style={{ width: "100%" }}
										value={filter ? filter.value : "all"}
									>
										<option value="">Todos</option>
										{Object.keys(userStatus).map(c => (
											<option key={c} value={c}>
												{ls[userStatus[c]]}
											</option>
										))}
									</select>
								)
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default Report;
