import React from "react";
import { SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";

import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { createBankAccount, editBankAccount, setValue } from "./actions";

import bankCodes from "assets/banks.json";

import accountType from "models/accountType";

import validate from "containers/User/components/bankAccount/validate";
import EditForm from "containers/User/components/bankAccount/edit";
import ls from "Localization";

function EditBankAccount({ load, user }) {
	const dispatch = useDispatch();

	const { showModalEdit, item } = useSelector(s => s.bankAccountEdit);

	const closeModal = () => {
		dispatch(setValue({ showModalEdit: false, item: null }));
	};

	const onSubmit = values => {
		const errors = validate(values);

		if (Object.keys(errors).length > 0) {
			throw new SubmissionError(errors);
		}

		if (item) {
			dispatch(
				editBankAccount(item._id, values, err => {
					if (err) {
						console.log(err);
						alert(err.default);
					} else {
						closeModal();
						load();
					}
				})
			);
		} else {
			values.user = user._id;
			dispatch(
				createBankAccount(values, err => {
					if (err) {
						console.log(err);
						alert(err.default);
					} else {
						closeModal();
						load();
					}
				})
			);
		}
	};

	const accountTypeData = Object.keys(accountType).map(c => ({
		value: c,
		label: ls[accountType[c]]
	}));

	const bankCodesData = Object.keys(bankCodes).map(c => ({
		code: bankCodes[c].code,
		label: `${bankCodes[c].code} - ${bankCodes[c].name}`
	}));

	let initialValues = {};

	if (item) {
		initialValues.bank = bankCodesData.find(c => c.code === String(item.bank));
		initialValues.agency = item.agency;
		initialValues.account = item.account;
		initialValues.type = accountTypeData.find(
			c => c.value === String(item.type)
		);
	}

	return (
		<Modal
			toggle={closeModal}
			className="theme-light ltr-support login-modal-form"
			isOpen={showModalEdit}
		>
			<ModalHeader>{!item ? "Cadastrar" : "Editar"} conta bancária</ModalHeader>
			<ModalBody>
				<EditForm
					onSubmit={onSubmit}
					closeModal={closeModal}
					initialValues={initialValues}
					accountTypes={accountTypeData}
					bankCodes={bankCodesData}
				/>
			</ModalBody>
		</Modal>
	);
}

export default EditBankAccount;
