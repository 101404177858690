import React from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import {
	FaDollarSign,
	FaUsers,
	FaHome,
	FaTicketAlt,
	FaMoneyBill,
	FaUserCog
} from "react-icons/fa";
import { useSelector } from "react-redux";
import userRole from "models/userRole";

function SidebarContent({ onClick }) {
	const hideSidebar = () => {
		onClick();
	};

	const { user } = useSelector(s => s.login);

	return (
		<div className="sidebar__content">
			<SidebarLink
				title="Dashboard"
				route="/"
				onClick={hideSidebar}
				icon={<FaHome />}
			/>
			<SidebarLink
				title="Comissões"
				icon={<FaDollarSign />}
				route="/commission"
				user={user}
				roles={[userRole.ADMIN, userRole.AFFILIATE]}
				onClick={hideSidebar}
			/>
			<SidebarLink
				title="Cupons"
				icon={<FaTicketAlt />}
				route="/coupon"
				user={user}
				roles={[userRole.ADMIN]}
				onClick={hideSidebar}
			/>
			<SidebarLink
				title="Pagamentos"
				icon={<FaMoneyBill />}
				route="/payment"
				user={user}
				roles={[userRole.ADMIN, userRole.AFFILIATE]}
				onClick={hideSidebar}
			/>
			<SidebarLink
				title="Usuários"
				icon={<FaUsers />}
				route="/user"
				user={user}
				roles={[userRole.ADMIN]}
				onClick={hideSidebar}
			/>

			<SidebarLink
				title="Meu perfil"
				route={`/user/${user.id}`}
				onClick={hideSidebar}
				user={user}
				roles={[userRole.ADMIN, userRole.AFFILIATE]}
				icon={<FaUserCog />}
			/>
		</div>
	);
}

SidebarContent.propTypes = {
	changeToDark: PropTypes.func.isRequired,
	changeToLight: PropTypes.func.isRequired,
	onClick: PropTypes.func.isRequired
};

export default SidebarContent;
