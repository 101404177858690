import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import PropTypes from "prop-types";
import validate from "./validate";

import Input from "../../../components/ReduxForm/Input";
import { Link } from "react-router-dom";

class LogInForm extends PureComponent {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired
	};

	constructor() {
		super();
		this.state = {
			showPassword: false
		};
	}

	showPassword = e => {
		e.preventDefault();
		this.setState(prevState => ({ showPassword: !prevState.showPassword }));
	};

	render() {
		const { handleSubmit } = this.props;
		const { showPassword } = this.state;

		return (
			<form className="form" onSubmit={handleSubmit}>
				<div className="form__form-group">
					<span className="form__form-group-label">E-mail</span>
					<div className="form__form-group-field">
						<div className="form__form-group-icon">
							<AccountOutlineIcon />
						</div>
						<Field
							name="email"
							type="email-address"
							placeholder="Seu e-mail"
							component={Input}
						/>
					</div>
				</div>
				<div className="form__form-group form__form-group--forgot">
					<span className="form__form-group-label">Senha</span>
					<div className="form__form-group-field">
						<div className="form__form-group-icon">
							<KeyVariantIcon />
						</div>
						<Field
							name="password"
							component={Input}
							type={showPassword ? "text" : "password"}
							placeholder="Sua senha"
						/>
						<button
							className={`form__form-group-button${
								showPassword ? " active" : ""
							}`}
							onClick={e => this.showPassword(e)}
							type="button"
						>
							<EyeIcon />
						</button>
						<div className="account__forgot-password">
							<Link to="/ForgotPassword">Recuperar senha</Link>
						</div>
					</div>
				</div>

				<button
					className="btn btn-primary account__btn account__btn--small"
					type="submit"
				>
					Entrar
				</button>

				<Link className="btn btn-outline-primary account__btn" to="/register">
					Criar conta
				</Link>
			</form>
		);
	}
}

export default reduxForm({
	form: "log_in_form",
	validate
})(LogInForm);
