import UserCouponDetail from "containers/User/components/coupon/detail";
import React from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, Row } from "reactstrap";

import { setValue } from "containers/Coupon/containers/Create/actions";
import CreateCoupon from "containers/Coupon/containers/Create";

function CouponDetail({ user }) {
	if (!user) return null;

	const dispatch = useDispatch();

	const handleCreate = e => {
		e.preventDefault();

		dispatch(setValue({ showCreateForm: true }));
	};

	return (
		<Card style={{ height: "unset" }}>
			<CardBody>
				<Row className="justify-content-between align-items-center">
					<div className="card__title">
						<h5 className="bold-text">Cupom do usuário</h5>
					</div>
				</Row>

				{user.coupon ? (
					<UserCouponDetail coupon={user.coupon} />
				) : (
					<div>
						Usuário sem cupom,{" "}
						<a
							href="javascript:void(0)"
							style={{ fontWeight: "bold" }}
							onClick={handleCreate}
						>
							clique aqui para criar um novo cupom
						</a>
						<CreateCoupon user={user} />
					</div>
				)}
			</CardBody>
		</Card>
	);
}

export default CouponDetail;
