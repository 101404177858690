import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import login from "./login";
import notification from "./notification";
import theme from "./theme";
import sidebar from "./sidebar";

import commission from "containers/Commission/containers/Report/reducer";
import commissionDetails from "containers/Commission/containers/Detail/reducer";
import commissionDelete from "containers/Commission/containers/Delete/reducer";
import commissionEdit from "containers/Commission/containers/Edit/reducer";

import user from "containers/User/containers/Report/reducer";
import userDetails from "containers/User/containers/Detail/reducer";
import userEdit from "containers/User/containers/Detail/Forms/EditUser/reducer";

import coupon from "containers/Coupon/containers/Report/reducer";
import couponCreate from "containers/Coupon/containers/Create/reducer";
import couponEdit from "containers/Coupon/containers/Edit/reducer";
import couponDelete from "containers/Coupon/containers/Delete/reducer";
import couponDetails from "containers/Coupon/containers/Detail/reducer";

import bankAccountEdit from "containers/User/containers/Detail/BankAccount/Forms/Edit/reducer";

import payment from "containers/Payment/containers/Report/reducer";

import register from "containers/Register/reducer";
import { USER_LOGGED_OUT } from "redux/actions/login";

const appReducer = combineReducers({
	form,
	login,
	register,
	notification,
	theme,
	commission,
	commissionDetails,
	commissionEdit,
	commissionDelete,
	user,
	userDetails,
	userEdit,
	coupon,
	couponCreate,
	couponDetails,
	couponEdit,
	couponDelete,
	sidebar,
	payment,
	bankAccountEdit
});

const rootReducer = (state, action) => {
	// when a logout action is dispatched it will reset redux state
	if (action.type === USER_LOGGED_OUT) {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
