const validate = values => {
	const errors = {};

	if (!values.code) {
		errors.code = "Informe um código";
	} else if (
		/[\u0300-\u036f|\s|!|@|#|$|%|&|*|(|)|-|_|=|+|[|\]|{|}|'|`|^|~|&]/.test(
			values.code.normalize("NFD")
		)
	) {
		errors.code =
			"Código não pode conter acentos, espaços ou caracteres especiais";
	}

	if (!values.value) {
		errors.value = "Informe um desconto";
	} else {
		let value = values.value;

		if (typeof value === "string") {
			value = value.match(/[\d-]/g).join("") / 100.0;
		}

		if (value < 0 || value > 100) {
			errors.value = "Valor precisa ser entre 0.01% e 100%";
		}
	}

	if (!values.commission) {
		errors.commission = "Informe uma comissão";
	} else {
		let commission = values.commission;

		if (typeof commission === "string") {
			commission = commission.match(/[\d-]/g).join("") / 100.0;
			console.log("commission", commission);
		}

		if (commission < 0 || commission > 100) {
			errors.commission = "Comissão precisa ser entre 0.01% e 100%";
		}
	}

	return errors;
};

export default validate;
