import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const GET_USER = "[USER DETAIL] GET_USER";
export const GET_USER_ERROR = "[USER DETAIL] GET_USER_ERROR";
export const GET_USER_SUCCESS = "[USER DETAIL] GET_USER_SUCCESS";

export const GET_COMMISSION_USER = "[USER DETAIL] GET_COMMISSION_USER";
export const GET_COMMISSION_USER_ERROR =
	"[USER DETAIL] GET_COMMISSION_USER_ERROR";
export const GET_COMMISSION_USER_SUCCESS =
	"[USER DETAIL] GET_COMMISSION_USER_SUCCESS";

export const SET_VALUE = "[USER DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[USER DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getUser(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_USER
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/user/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_USER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_USER_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_USER_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getCommissionForUser(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_COMMISSION_USER
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/commission/user/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_COMMISSION_USER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_COMMISSION_USER_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_COMMISSION_USER_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const EDIT_USER_EDIT = "[USER EDIT] EDIT_USER_EDIT";
export const EDIT_USER_EDIT_ERROR = "[USER EDIT] EDIT_USER_EDIT_ERROR";
export const EDIT_USER_EDIT_SUCCESS = "[USER EDIT] EDIT_USER_EDIT_SUCCESS";

export function editUser(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_USER_EDIT
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/user/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_USER_EDIT_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: EDIT_USER_EDIT_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: EDIT_USER_EDIT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const CHANGE_STATUS = "[USER EDIT] CHANGE_STATUS";
export const CHANGE_STATUS_ERROR = "[USER EDIT] CHANGE_STATUS_ERROR";
export const CHANGE_STATUS_SUCCESS = "[USER EDIT] CHANGE_STATUS_SUCCESS";

export function changeUserStatus(id, status, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CHANGE_STATUS
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/user/${id}/status/${status}`, null, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CHANGE_STATUS_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: CHANGE_STATUS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: CHANGE_STATUS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const GET_CONFIG = "[USER DETAIL] GET_CONFIG";
export const GET_CONFIG_ERROR = "[USER DETAIL] GET_CONFIG_ERROR";
export const GET_CONFIG_SUCCESS = "[USER DETAIL] GET_CONFIG_SUCCESS";

export function getConfig(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CONFIG
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/config`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CONFIG_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_CONFIG_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_CONFIG_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const CHANGE_STATUS_COMMISSION = "[USER EDIT] CHANGE_STATUS_COMMISSION";
export const CHANGE_STATUS_COMMISSION_ERROR =
	"[USER EDIT] CHANGE_STATUS_COMMISSION_ERROR";
export const CHANGE_STATUS_COMMISSION_SUCCESS =
	"[USER EDIT] CHANGE_STATUS_COMMISSION_SUCCESS";

export function changeCommissionStatus(id, paymentDate, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CHANGE_STATUS_COMMISSION
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(
				`/commission/user/${id}/paymentDate/${paymentDate}`,
				null,
				{
					Authorization: "Bearer " + login.token
				}
			);

			if (!response.ok) {
				console.log(await response.text());
			}
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CHANGE_STATUS_COMMISSION_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: CHANGE_STATUS_COMMISSION_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			console.log(error);
			return dispatch({
				type: CHANGE_STATUS_COMMISSION_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
