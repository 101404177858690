import React from "react";
import DeleteForm from "./DeleteForm";
import PropTypes from "prop-types";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { setValue,   deleteCommission } from "./actions";
import { getCommissions } from "../Report/actions";
import { SubmissionError } from "redux-form";
import ls from "Localization";
import dayjs from "dayjs";

function Delete({ styles, load }) {
	const dispatch = useDispatch();

	const { showDeleteForm } = useSelector(s => s.commissionDelete);
	const d = showDeleteForm;
	if (!d) return null;

	const onSubmit = () => {
		dispatch(
			deleteCommission(d._id, err => {
				if (err) {
					throw new SubmissionError(err);
				} else {
					closeModal();
					load && load();
				}
			})
		);
		
	};

	const closeModal = () => {
		dispatch(
			setValue({
				showDeleteForm: false
			})
		);
	};

	const initialValues = {
		name: d.user.fullName,
		date: dayjs(d.createdAt).format(ls.dateTimeFormatWithoutSeconds)
	};

	return (
		<Modal
			toggle={closeModal}
			className="theme-light ltr-support login-modal-form"
			isOpen={Boolean(showDeleteForm)}
		>
			<ModalHeader>Deletar Comissão</ModalHeader>
			<ModalBody>
				<DeleteForm
					styles={styles}
					closeModal={closeModal}
					initialValues={initialValues}
					onSubmit={onSubmit}
				/>
			</ModalBody>
		</Modal>
	);
}

export default Delete;

Delete.propTypes = {
	onChange: PropTypes.func,
	styles: PropTypes.object
};