const validate = values => {
	const errors = {};

	if (!values.bank) errors.bank = "Informe o código do banco";
	else if (values.bank.length > 3)
		errors.bank = "O código do banco é composto por três dígitos";

	if (!values.agency) errors.agency = "Informe o número da agência";
	else if (values.agency.length > 12)
		errors.agency = "O número da agencia deve conter até 12 dígitos";

	if (!values.account) errors.account = "Informe o número da conta";
	else if (values.account.length > 12)
		errors.account = "O número da conta deve conter até 12 dígitos";

	if (!values.type) errors.type = "Informe o tipo da conta";

	return errors;
};

export default validate;
