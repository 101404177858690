import * as api from "services/api";
import { getErrors } from "helpers/error";

export const EDIT_USER = "[USER_COUPON EDIT] EDIT_USER";
export const EDIT_USER_ERROR = "[USER_COUPON EDIT] EDIT_USER_ERROR";
export const EDIT_USER_SUCCESS = "[USER_COUPON EDIT] EDIT_USER_SUCCESS";

export const SET_VALUE = "[USER_COUPON EDIT] SET_VALUE";
export const CLEAR_VALUES = "[USER_COUPON EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function editUser(id, data, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_USER
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/user/${id}`, data, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: EDIT_USER_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				callback && callback(getErrors(result.errors));

				dispatch({
					type: EDIT_USER_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			callback && callback();

			return dispatch({
				type: EDIT_USER_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
