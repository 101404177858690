import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import actions from "redux/actions";
import { submitRegister } from "./actions";

import ls from "Localization";

import RegisterForm from "./components/RegisterForm";

import bankCodes from "assets/banks.json";

import accountType from "models/accountType";

const Register = ({ history }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(actions.register.clearValues());
	}, []);

	const { loading } = useSelector(c => c.register);

	const onSubmit = data => {
		dispatch(
			submitRegister(data, err => {
				if (err) {
					if (err.default) alert(err.default);
					console.log(err);
				} else {
					dispatch(
						actions.login.setValue({
							message:
								"Cadastro realizado com sucesso, iremos verificar suas informações e lhe informaremos por e-mail quando sua conta estiver ativada"
						})
					);

					history.push("/login");
				}
			})
		);
	};

	const accountTypeData = Object.keys(accountType).map(c => ({
		value: c,
		label: ls[accountType[c]]
	}));

	const bankCodesData = Object.keys(bankCodes).map(c => ({
		code: bankCodes[c].code,
		label: `${bankCodes[c].code} - ${bankCodes[c].name}`
	}));

	return (
		<div className="account">
			<div className="account__wrapper">
				<div className="account__card">
					<div className="account__head">
						<div className="account__logo" />
						<div className="flex-center">
							<h3 className="account__title">Programa de afiliados</h3>

							<h4 className="account__subhead subhead">
								Crie sua conta e receba comissões
							</h4>
						</div>
					</div>
					<RegisterForm
						onSubmit={onSubmit}
						loading={loading.register}
						accountTypes={accountTypeData}
						bankCodes={bankCodesData}
					/>
					<div className="account__have-account">
						<p>
							Já tem conta? <Link to="/login">Entre aqui</Link>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Register;
