import React, { useEffect } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import CommissionDetail from "../../components/detail";
import { getCommission } from "./actions";

function Details({
	match: {
		params: { id }
	}
}) {
	const { item } = useSelector(state => state.commissionDetails);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCommission(id));
	}, [dispatch]);

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes da comissão</h5>
								</div>
							</Row>
							<CommissionDetail
								data={{
									...item
								}}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Details;
