import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	Button,
	Spinner
} from "reactstrap";

import {
	getUser,
	clearValues,
	getCommissionForUser,
	changeUserStatus,
	getConfig
} from "./actions";
import { setValue } from "containers/Coupon/containers/Edit/actions";
import { setValue as setValueUser } from "containers/User/containers/Detail/Forms/EditUser/actions";

import CouponDetail from "./Coupon";
import BankAccountDetail from "./BankAccount";

import Commissions from "./Commissions";
import EditCoupon from "containers/Coupon/containers/Edit";
import EditUser from "containers/User/containers/Detail/Forms/EditUser";
import EditBankAccount from "containers/User/containers/Detail/BankAccount/Forms/Edit";

import DetailsPerson from "../../components/detail";
import userRole from "models/userRole";

import { isInRole } from "helpers/auth";

function Details({
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();
	const { user } = useSelector(s => s.login);
	const { item, commissions, loading } = useSelector(
		state => state.userDetails
	);

	const loadUser = () => {
		dispatch(getUser(id));
	};

	useEffect(() => {
		dispatch(clearValues());
		loadUser();
		dispatch(getCommissionForUser(id));
		dispatch(getConfig());
	}, [dispatch]);

	const handleEnable = () => {
		dispatch(
			changeUserStatus(id, 1, err => {
				if (err) {
					console.log(err);
				} else {
					dispatch(getUser(id));
				}
			})
		);
	};

	const showConfirmCoupon = () => {
		dispatch(setValue({ item: item.coupon, showEditForm: true }));
	};

	const showModalEditUser = () => {
		dispatch(setValueUser({ item: item, showModalEditUser: true }));
	};

	const handleDisable = () => {
		dispatch(
			changeUserStatus(id, 2, err => {
				if (err) {
					console.log(err);
				} else {
					dispatch(getUser(id));
				}
			})
		);
	};

	// if (!item) return null;

	const isAdmin = isInRole(user, [userRole.ADMIN]);

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card style={{ height: "unset" }}>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes do usuário</h5>
									{loading.get && (
										<Spinner type="grow" size="sm" color="dark" />
									)}
								</div>

								<div>
									{isAdmin &&
										(item && item.status !== 1 ? (
											<Button color="primary" onClick={showConfirmCoupon}>
												Ativar
											</Button>
										) : (
											<Button color="danger" onClick={handleDisable}>
												Desativar
											</Button>
										))}
									<Button color="secondary" onClick={showModalEditUser}>
										Editar
									</Button>
								</div>
							</Row>
							<DetailsPerson
								client={item}
								loading={loading}
								isAdmin={isAdmin}
							/>
						</CardBody>
					</Card>

					<BankAccountDetail user={item} />
					<CouponDetail user={item} />
					{user && user.role === userRole.ADMIN.toUpperCase() && (
						<Commissions
							commissions={commissions}
							loading={loading}
							user_id={id}
						/>
					)}
				</Col>
			</Row>
			<EditUser load={loadUser} />
			<EditBankAccount load={loadUser} user={item} />
			<EditCoupon load={handleEnable} confirm />
		</Container>
	);
}

export default Details;
