import React from "react";
import { Field, reduxForm } from "redux-form";

import { Button, ButtonToolbar, Container, Col, Row } from "reactstrap";

import ls from "Localization";

import validate from "./validate";

import Input from "components/ReduxForm/Input";
import renderSelectField from "shared/components/form/Select";

const EditForm = ({ handleSubmit, closeModal, ...props }) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">{ls.bank}</span>
						<div className="form__form-group-field">
							<Field
								name="bank"
								placeholder="Selecione"
								component={renderSelectField}
								options={props.bankCodes || []}
							/>
						</div>
					</Col>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">{ls.accountType}</span>
						<div className="form__form-group-field">
							<Field
								name="type"
								placeholder="Selecione"
								component={renderSelectField}
								options={props.accountTypes || []}
							/>
						</div>
					</Col>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">{ls.agency}</span>
						<div className="form__form-group-field">
							<Field name="agency" component={Input} type="text" />
						</div>
					</Col>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">{ls.account}</span>
						<div className="form__form-group-field">
							<Field name="account" component={Input} type="text" />
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit">
					Salvar
				</Button>
				<Button color="cancel" type="button" onClick={closeModal}>
					Cancelar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "bank_account_edit",
	validate
})(EditForm);
