const validate = values => {
	const errors = {};

	if (!values.paymentDate) {
		errors.paymentDate = "Informe o dia de pagamento";
	}
	else if(
		/[\d{2}/\d{2}/\d{4}]/.test(values.paymentDate)===false){
		errors.paymentDate =
			"Informe uma Data válida";
	}

	if (!values.value) {
		errors.value = "Informe um Valor";
	}

	if (!values.status) {
		errors.status = "Selecione um status";
	} 
	

	return errors;
};

export default validate;
