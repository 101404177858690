import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const DELETE_COUPON = "[USER_COUPON DELETE] DELETE_COUPON";
export const DELETE_COUPON_ERROR = "[USER_COUPON DELETE] DELETE_COUPON_ERROR";
export const DELETE_COUPON_SUCCESS = "[USER_COUPON DELETE] DELETE_COUPON_SUCCESS";

export const SET_VALUE = "[USER_COUPON DELETE] SET_VALUE";
export const CLEAR_VALUES = "[USER_COUPON DELETE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function deleteCoupon(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_COUPON
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/coupon/${id}`, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: DELETE_COUPON_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				dispatch({
					type: DELETE_COUPON_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: DELETE_COUPON_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
