import React, { useEffect } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import CouponDetail from "../../components/detail";
import { getCoupon } from "./actions";

function Details({
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();

	const { item } = useSelector(state => state.couponDetails);

	useEffect(() => {
		dispatch(getCoupon(id));
	}, [dispatch]);

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes do cupom</h5>
								</div>
							</Row>
							<CouponDetail
								data={{
									...item
								}}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Details;
