import React from "react";
import { Card, Row, Col, Container } from "reactstrap";
import dayjs from "dayjs";
import ls from "Localization";

const CommissionDetail = ({ client }) => {
	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{client.createdAt
												? dayjs(client.createdAt).format(
														ls.dateTimeFormatWithoutSeconds
												  )
												: "--"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Nome: </strong>
										<br />
										<span>{client.name ? client.name : "--"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Sobrenome: </strong>
										<br />
										<span>{client.lastname ? client.lastname : "--"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>CPF: </strong>
										<br />
										<span>{client.cpf ? client.cpf : "--"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>{ls.birthDate}: </strong>
										<br />
										<span>
											{client.birthDate
												? dayjs(client.birthDate).format(
														ls.dateTimeFormatWithoutSeconds
												  )
												: "--"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Telefone: </strong>
										<br />
										<span>
											{client.phoneNumber ? client.phoneNumber : "--"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>{ls.role}: </strong>
										<br />
										<span>{client.role ? ls[client.role] : "--"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Email: </strong>
										<br />
										<span>{client.email ? client.email : "--"}</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default CommissionDetail;
