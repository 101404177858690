import { isInRole } from "helpers/auth";
import { toStringCurrency } from "helpers/string";
import userRole from "models/userRole";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import Panel from "../../../shared/components/Panel";
import { getTopAffiliates } from "../../Commission/containers/Report/actions";

function TopAffiliates() {
	const dispatch = useDispatch();

	const { user } = useSelector(s => s.login);
	const { topAffiliates } = useSelector(s => s.commission);

	const load = () => {
		if (isInRole(user, [userRole.ADMIN])) {
			dispatch(
				getTopAffiliates(err => {
					if (err) console.log(err);
				})
			);
		}
	};

	useEffect(() => {
		load();
	}, []);

	if (!isInRole(user, [userRole.ADMIN])) return null;

	return (
		<Panel
			md={12}
			lg={6}
			title="Top Afiliados"
			subhead=""
			panelClass="dashboard__booking-reservations-panel"
		>
			<div className="dashboard__booking-reservations">
				<Table responsive className="table dashboard__occupancy-table">
					<thead>
						<tr>
							{<th>Afiliado</th>}
							<th>QTD Pedidos</th>
							<th>Valor Total</th>
							<th>Comissão Total</th>
						</tr>
					</thead>
					<tbody>
						{[...(topAffiliates || [])].map((c, i) => (
							<tr key={i}>
								{isInRole(user, [userRole.ADMIN]) ? (
									<td>
										<Link to={`/user/${c.user ? c.user._id : ""}`}>
											{c.user ? `${c.user.firstName} ${c.user.lastName}` : ""}
										</Link>
									</td>
								) : null}
								<td>{c.count}</td>
								<td>{toStringCurrency(c.total - c.discount)}</td>
								<td>{toStringCurrency(c.commissions)}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</div>
		</Panel>
	);
}

export default TopAffiliates;
