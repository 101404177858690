import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { deleteCoupon, setValue } from "./actions";
import DeleteForm from "../../components/delete";

function DeleteCoupon({ load }) {
	const dispatch = useDispatch();

	const { showDeleteForm, item } = useSelector(s => s.couponDelete);

	const onSubmit = () => {

			dispatch(
				deleteCoupon(
					item._id,
					err => {
						if (err) {
							console.log(err);
						} else {
							load && load();
						}
					}
				)
			);
		
	};

	const closeModal = () => {
		dispatch(setValue({ showDeleteForm: false, item: null }));
	};

	const initialValues = {
		code: item && item.code
	};

	return (
		<Modal
			toggle={closeModal}
			className="theme-light ltr-support login-modal-form"
			isOpen={showDeleteForm}
		>
			<ModalHeader>Deletar cupom</ModalHeader>
			<ModalBody>
				<DeleteForm
					onSubmit={onSubmit}
					closeModal={closeModal}
					initialValues={initialValues}
				/>
			</ModalBody>
		</Modal>
	);
}

export default DeleteCoupon;
