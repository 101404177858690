const strings = {
	client: "Cliente",
	admin: "Administrador",
	Pending: "Pendente",
	Enabled: "Ativo",
	Disabled: "Desativado",
	Paid: "Pago",
	Canceled: "Cancelado",
	"you need to reset your password":
		"Senha expirada, clique em recuperar senha",
	newPasswordSentForEmail: "Nova senha enviada para o e-mail informado",
	"User registration is pending":
		"Seu cadastro está pendente de aprovação, iremos verificar seu cadastro em breve, você será informado por e-mail.",
	"User is disabled": "Acesso desativado pelo administrador do sistema",
	role: "Permissão",
	ADMIN: "Administrador",
	PRINT_PARTNER: "Gráfica",
	CARRIER_PARTNER: "Transportadora",
	AFFILIATE: "Afiliado",
	NAUGHT: "Nenhum",
	dateTimeFormatWithoutSeconds: "DD/MM/YYYY - HH:mm",
	dateTimeFormat: "DD/MM/YYYY - HH:mm:ss",
	dateFormat: "DD/MM/YYYY",
	Absolute: "Absoluto",
	Percent: "Porcentagem",
	Available: "Disponível",
	Used: "Usado",
	Cancelled: "Cancelado",
	MGM: "Indicação",
	Campaign: "Campanha",
	Sac: "Atendimento",
	"Coupon already exists": "Cupom já existe",
	Checking: "Conta corrente",
	Savings: "Conta poupança",
	name: "Nome",
	lastname: "Sobrenome",
	cpf: "CPF",
	email: "E-mail",
	telephone: "Telefone",
	requiredField: "Campo obrigatório",
	bank: "Banco",
	agency: "Agência",
	account: "Conta",
	accountType: "Tipo de conta"
};

export default strings;
