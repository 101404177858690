import { AuthRoute } from "containers/App/Router";
import userRole from "models/userRole";
import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Detail from "./containers/Detail";
import Report from "./containers/Report";

const routes = () => {
	const { user } = useSelector(s => s.login);

	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/user/:id" component={Detail} />
				<AuthRoute
					exact
					path="/user"
					component={Report}
					user={user}
					needRoles={[userRole.ADMIN]}
				/>
			</Switch>
		</React.Fragment>
	);
};

export default routes;
