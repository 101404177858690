import ReportTable from "components/Report";
import dayjs from "dayjs";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import React, { useEffect, useRef } from "react";
import { MdCheck } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Card,
	CardBody,
	Col,
	Row
} from "reactstrap";
import { getCommissionForUser, setValue } from "../actions";
import StatusPayment from "../Status";

function Commissions({ commissions, loading, user_id }) {
	if (!commissions) return null;

	const table = useRef();
	const dispatch = useDispatch();

	const handleChange = data => {
		dispatch(
			setValue({
				statusPayment: {
					form: true,
					user_id,
					paymentDate: data.paymentDate
				}
			})
		);
	};

	const load = () => {
		dispatch(getCommissionForUser(user_id));
	};

	useEffect(() => {
		load();
	}, []);

	return (
		<Card style={{ height: "unset" }}>
			<CardBody>
				<Row>
					<Col md={12}>
						<ReportTable
							title="Comissões do usuário"
							data={commissions}
							tableRef={table}
							showPagination={false}
							minRows={
								commissions.length < 10
									? commissions.length + 1
									: commissions.length || 1
							}
							loading={loading.get}
							defaultSorted={[
								{
									id: "paymentDate",
									desc: false
								}
							]}
							columns={[
								{
									Header: "Data Pagamento",
									id: "paymentDate",
									accessor: c =>
										c.paymentDate
											? dayjs(c.paymentDate).format(ls.dateFormat)
											: "Não definido"
								},
								{
									Header: "Total pedido R$",
									id: "totalOrder",
									accessor: c => toStringCurrency(c.totalOrder - c.discount),
									format: c => {
										return Number(c.totalOrder - c.discount);
									},
									width: 120
								},
								{
									Header: "Qtd pedido",
									id: "count",
									accessor: c => c.count,
									width: 100
								},
								{
									Header: "Total",
									id: "total",
									accessor: c => toStringCurrency(c.total),
									format: c => {
										return Number(c.total);
									},
									width: 100
								},
								{
									Header: "Valor Pago",
									id: "paid",
									accessor: c => toStringCurrency(c.paid),
									format: c => {
										return Number(c.paid);
									},
									width: 100
								},
								{
									Header: "Valor Pendente",
									id: "pending",
									accessor: c => toStringCurrency(c.pending),
									format: c => {
										return Number(c.pending);
									},
									width: 100
								},
								{
									Header: "Ações",
									id: "actions",
									width: 80,
									Cell: ({ original, index }) => (
										<div
											style={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
												flex: 1
											}}
										>
											{commissions[index].pending > 0 ? (
												<ButtonToolbar>
													<ButtonGroup className="btn-group--icons" dir="ltr">
														<Button onClick={() => handleChange(original)}>
															<MdCheck style={{ margin: 0 }} />
														</Button>
													</ButtonGroup>
												</ButtonToolbar>
											) : (
												""
											)}
										</div>
									)
								}
							]}
						/>
					</Col>
				</Row>
			</CardBody>
			<StatusPayment />
		</Card>
	);
}

export default Commissions;
