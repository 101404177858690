import React from "react";
import { Card, Row, Col, Container, Spinner } from "reactstrap";
import dayjs from "dayjs";
import ls from "Localization";
import userStatus from "models/userStatus";
import { CPF } from "components/NumberFormat";

const UserDetail = ({ client, loading, isAdmin }) => {
	if (!client) return null;

	return (
		<div>
			{loading.get && <Spinner type="grow" size="sm" color="dark" />}
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{client.createdAt
												? dayjs(client.createdAt).format(ls.dateTimeFormat)
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Nome: </strong>
										<br />
										<span>
											{client.firstName ? client.firstName : "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Sobrenome: </strong>
										<br />
										<span>
											{client.lastName ? client.lastName : "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									{client.document ? (
										<CPF
											displayType="text"
											value={client.document}
											renderText={v => {
												return (
													<p>
														<strong>CPF: </strong>
														<br />
														<span>{v}</span>
													</p>
												);
											}}
										/>
									) : (
										<p>
											<strong>CPF: </strong>
											<br />
											<span>Não definido</span>
										</p>
									)}
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Telefone: </strong>
										<br />
										<span>
											{client.nationalPhoneNumber
												? client.nationalPhoneNumber
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Email: </strong>
										<br />
										<span>{client.email ? client.email : "Não definido"}</span>
									</p>
								</Col>
								{isAdmin && (
									<>
										<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
											<p>
												<strong>{ls.role}: </strong>
												<br />
												<span>
													{client.role ? ls[client.role] : "Não definido"}
												</span>
											</p>
										</Col>

										<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
											<p>
												<strong>Status: </strong>
												<br />
												<span>{ls[userStatus[client.status]]}</span>
											</p>
										</Col>
									</>
								)}
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default UserDetail;
