import React from "react";
import { Field, reduxForm } from "redux-form";

import { Button, ButtonToolbar, Container, Col, Row } from "reactstrap";

import ls from "Localization";

import validate from "./validate";

import { CPF } from "components/NumberFormat";
import Input from "components/ReduxForm/Input";
import { PHONE } from "components/NumberFormat";

const EditForm = ({ handleSubmit, closeModal }) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">{ls.name}</span>
						<div className="form__form-group-field">
							<Field name="firstName" component={Input} type="text" />
						</div>
					</Col>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">{ls.lastname}</span>
						<div className="form__form-group-field">
							<Field name="lastName" component={Input} type="text" />
						</div>
					</Col>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">{ls.cpf}</span>
						<div className="form__form-group-field">
							<Field name="document" component={CPF} type="text" />
						</div>
					</Col>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">{ls.telephone}</span>
						<div className="form__form-group-field">
							<Field name="nationalPhoneNumber" component={PHONE} type="text" />
						</div>
					</Col>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">{ls.email}</span>
						<div className="form__form-group-field">
							<Field name="email" component={Input} type="email" />
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit">
					Salvar
				</Button>
				<Button color="cancel" type="button" onClick={closeModal}>
					Cancelar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "user_edit",
	validate
})(EditForm);
