import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button, ButtonToolbar, Container, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import renderDatePickerField from "shared/components/form/DatePicker";
import renderSelectField from "shared/components/form/Select";
import { MONEY } from "components/NumberFormat";
import validate from "./validate"


const EditForm = ({ handleSubmit, closeModal, initialValues, statusValues }) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Dia de Pagamento</span>
						<div className="form__form-group-field">
							<Field
								name="paymentDate"
								component={renderDatePickerField}

							/>
						</div>
					</Col>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Status</span>
						<div className="form__form-group-field">
							<Field
								name="status"
								component={renderSelectField}
								options={statusValues}
							/>
						</div>
					</Col>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Valor</span>
						<div className="form__form-group-field">
							<Field
								name="value"
								component={MONEY}
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit">
					Salvar
				</Button>
				<Button color="cancel" type="button" onClick={closeModal}>
					Cancelar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "edit_commission",
	validate
})(EditForm);

EditForm.propTypes = {
	handleSubmit: PropTypes.func,
	closeModal: PropTypes.func
};