import CreateForm from "containers/Coupon/components/create";
import validate from "containers/Coupon/components/validate";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { SubmissionError } from "redux-form";
import { getUser } from "containers/User/containers/Detail/actions";
import { createCoupon, setValue } from "./actions";
import { removeDiacritics, toStringPercentage } from "helpers/string";

function CreateCoupon({ user }) {
	const dispatch = useDispatch();

	const onSubmit = data => {
		const errors = validate(data);

		if (Object.keys(errors).length > 0) {
			throw new SubmissionError(errors);
		}

		if (typeof data.value === "number") data.value = data.value.toFixed(2);
		if (typeof data.commission === "number")
			data.commission = data.value.toFixed(2);

		dispatch(
			createCoupon(
				{
					...data,
					value:
						String(data.value)
							.match(/[\d-]/g)
							.join("") / 100.0,
					commission:
						String(data.commission)
							.match(/[\d-]/g)
							.join("") / 100.0,
					user: user._id,
					status: "AVAILABLE",
					origin: "CAMPAIGN",
					commissionType: "PERCENT",
					discountType: "PERCENT",
					dateCreated: new Date()
				},
				err => {
					if (err) {
						if (err.default) alert(err.default);
						console.log(err);
					} else {
						dispatch(getUser(user._id));
					}
				}
			)
		);
	};
	const { config } = useSelector(state => state.userDetails);
	const { showCreateForm } = useSelector(s => s.couponCreate);

	const closeModal = () => {
		dispatch(setValue({ showCreateForm: false }));
	};

	const initialValues = {
		code: removeDiacritics(`${user.firstName ?? ""}${user.lastName ?? ""}`)
			.trim()
			.toUpperCase(),
		value: toStringPercentage(
			(config && config.defaultDiscount) || 0,
			100,
			2
		).replace(".", ","),
		commission: toStringPercentage(
			(config && config.defaultCommission) || 0,
			100,
			2
		).replace(".", ",")
	};

	return (
		<Modal
			toggle={closeModal}
			className="theme-light ltr-support login-modal-form"
			isOpen={showCreateForm}
		>
			<ModalHeader>Criar novo cupom</ModalHeader>
			<ModalBody>
				<CreateForm
					onSubmit={onSubmit}
					closeModal={closeModal}
					initialValues={initialValues}
				/>
			</ModalBody>
		</Modal>
	);
}

export default CreateCoupon;
