import validate from "containers/Coupon/components/validate";
import { removeDiacritics, toStringPercentage } from "helpers/string";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { SubmissionError } from "redux-form";
import { editCoupon, setValue } from "./actions";
import EditForm from "../../components/edit";
import { createCoupon } from "../Create/actions";

function EditCoupon({ load, confirm }) {
	const dispatch = useDispatch();

	const { config } = useSelector(state => state.userDetails);
	const { showEditForm, item } = useSelector(s => s.couponEdit);
	const { user } = useSelector(c => c.login);

	const onSubmit = data => {
		const errors = validate(data);

		if (Object.keys(errors).length > 0) {
			throw new SubmissionError(errors);
		}

		if (typeof data.value === "number") data.value = data.value.toFixed(2);
		if (typeof data.commission === "number")
			data.commission = data.value.toFixed(2);

		if (item) {
			dispatch(
				editCoupon(
					item._id,
					{
						...data,
						value:
							String(data.value)
								.match(/[\d-]/g)
								.join("") / 100.0,
						commission:
							String(data.commission)
								.match(/[\d-]/g)
								.join("") / 100.0
					},
					err => {
						if (err) {
							alert(err.default);
							console.log(err);
						} else {
							load && load();
						}
					}
				)
			);
		} else {
			dispatch(
				createCoupon(
					{
						...data,
						value:
							String(data.value)
								.match(/[\d-]/g)
								.join("") / 100.0,
						commission:
							String(data.commission)
								.match(/[\d-]/g)
								.join("") / 100.0,
						user: user._id,
						status: "AVAILABLE",
						origin: "CAMPAIGN",
						commissionType: "PERCENT",
						discountType: "PERCENT",
						dateCreated: new Date()
					},
					err => {
						if (err) {
							alert(err.default);
							console.log(err);
						} else {
							closeModal();
							load && load(true);
						}
					}
				)
			);
		}
	};

	const closeModal = () => {
		dispatch(setValue({ showEditForm: false, item: null }));
	};

	const initialValues = {};

	if (item) {
		initialValues.code = item.code;
		initialValues.value = toStringPercentage(item.value || 0, 100, 2).replace(
			".",
			","
		);
		initialValues.commission = toStringPercentage(
			item.commission || 0,
			100,
			2
		).replace(".", ",");
	} else {
		initialValues.code -
			removeDiacritics(`${user.firstName ?? ""}${user.lastName ?? ""}`)
				.trim()
				.toUpperCase();
		initialValues.value = toStringPercentage(
			(config && config.defaultDiscount) || 0,
			100,
			2
		).replace(".", ",");
		initialValues.commission = toStringPercentage(
			(config && config.defaultCommission) || 0,
			100,
			2
		).replace(".", ",");
	}

	return (
		<Modal
			toggle={closeModal}
			className="theme-light ltr-support login-modal-form"
			isOpen={showEditForm}
		>
			<ModalHeader>{confirm ? "Confirme o cupom" : "Editar cupom"}</ModalHeader>
			<ModalBody>
				<EditForm
					onSubmit={onSubmit}
					closeModal={closeModal}
					initialValues={initialValues}
					user={user}
				/>
			</ModalBody>
		</Modal>
	);
}

export default EditCoupon;
