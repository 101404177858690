import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button, ButtonToolbar, Container, Col, Row } from "reactstrap";
import validate from "./validate";
import Input from "components/ReduxForm/Input";
import { PERCENTAGE2 } from "components/NumberFormat";

const CreateForm = ({ handleSubmit, closeModal }) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Código</span>
						<div className="form__form-group-field">
							<Field
								name="code"
								component={Input}
								type="text"
								placeholder="Ex.: USER10OFF"
							/>
						</div>
					</Col>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Desconto</span>
						<div className="form__form-group-field">
							<Field
								name="value"
								component={PERCENTAGE2}
								type="text"
								placeholder="Ex.: 10.00%"
							/>
						</div>
					</Col>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Comissão</span>
						<div className="form__form-group-field">
							<Field
								name="commission"
								component={PERCENTAGE2}
								type="text"
								placeholder="Ex.: 10.00%"
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit">
					Salvar
				</Button>
				<Button color="cancel" type="button" onClick={closeModal}>
					Cancelar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "user_coupon_create",
	validate
})(CreateForm);
