import React from "react";
import { SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";

import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { editUser, setValue } from "./actions";

import validate from "containers/User/components/Forms/editUser/validate";
import EditForm from "containers/User/components/Forms/editUser/index";

function EditUser({ load }) {
	const dispatch = useDispatch();

	const { showModalEditUser, item } = useSelector(s => s.userEdit);

	const closeModal = () => {
		dispatch(setValue({ showModalEditUser: false, item: null }));
	};

	const onSubmit = values => {
		const errors = validate(values);

		if (Object.keys(errors).length > 0) {
			throw new SubmissionError(errors);
		}

		dispatch(
			editUser(item._id, values, err => {
				if (err) {
					console.log(err);
					alert(err.default);
				} else {
					closeModal();
					load && load();
				}
			})
		);
	};

	if (!item) return null;

	const initialValues = {
		firstName: item.firstName,
		lastName: item.lastName,
		document: item.document,
		nationalPhoneNumber: item.nationalPhoneNumber,
		email: item.email
	};

	return (
		<Modal
			toggle={closeModal}
			className="theme-light ltr-support login-modal-form"
			isOpen={showModalEditUser}
		>
			<ModalHeader>Editar usuário</ModalHeader>
			<ModalBody>
				<EditForm
					onSubmit={onSubmit}
					closeModal={closeModal}
					initialValues={initialValues}
					user={item}
				/>
			</ModalBody>
		</Modal>
	);
}

export default EditUser;
