import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { isInRole, isLogged } from "../../helpers/auth";

import Layout from "../Layout";
import MainWrapper from "./MainWrapper";

import Dashboard from "../Dashboard";
import LogIn from "../LogIn";
import CommissionRoutes from "../Commission/routes";
import CouponRoutes from "../Coupon/routes";
import UserRoutes from "../User/routes";
import PaymentRoutes from "../Payment/routes";
import Register from "../Register/routes";
import ForgotPassword from "../ForgotPassword";

import { useSelector } from "react-redux";
import userRole from "models/userRole";

export const AuthRoute = ({ user, needRoles, ...rest }) => {
	if (isInRole(user, needRoles)) return <Route {...rest} />;

	return null;
};

const wrappedRoutes = () => {
	const { user } = useSelector(s => s.login);

	return (
		<div>
			<Layout />
			<div className="container__wrap">
				<AuthRoute
					path="/"
					exact
					component={Dashboard}
					user={user}
					needRoles={[userRole.ADMIN, userRole.AFFILIATE]}
				/>
				<AuthRoute
					path="/commission"
					component={CommissionRoutes}
					user={user}
					needRoles={[userRole.ADMIN, userRole.AFFILIATE]}
				/>
				<AuthRoute
					path="/coupon"
					component={CouponRoutes}
					user={user}
					needRoles={[userRole.ADMIN]}
				/>
				<AuthRoute
					path="/payment"
					component={PaymentRoutes}
					user={user}
					needRoles={[userRole.ADMIN, userRole.AFFILIATE]}
				/>
				<AuthRoute
					path="/user"
					component={UserRoutes}
					user={user}
					needRoles={[userRole.ADMIN, userRole.AFFILIATE]}
				/>
			</div>
		</div>
	);
};

const Router = () => {
	const login = useSelector(s => s.login);

	if (!login) return null;

	const loggedIn = isLogged(login);

	if (!loggedIn) {
		return (
			<MainWrapper>
				<main>
					<Switch>
						<Route exact path="/Login" component={LogIn} />
						<Route exact path="/Register" component={Register} />
						<Route exact path="/ForgotPassword" component={ForgotPassword} />
						<Redirect to="/Login" />
					</Switch>
				</main>
			</MainWrapper>
		);
	}

	return (
		<MainWrapper>
			<main>
				<Switch>
					<Route path="/" component={wrappedRoutes} />
					<Redirect to="/" />
				</Switch>
			</main>
		</MainWrapper>
	);
};

export default Router;
