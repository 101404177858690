import { toStringPercentage } from "helpers/string";
import React from "react";
import { Card, Col, Container, Row } from "reactstrap";

const UserCouponDetail = ({ coupon }) => {
	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Código: </strong>
										<br />
										<span>{coupon.code}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Desconto: </strong>
										<br />
										<span>{toStringPercentage(coupon.value, 100, 2)}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Comissão: </strong>
										<br />
										<span>{toStringPercentage(coupon.commission, 100, 2)}</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default UserCouponDetail;
