import React from "react";
import { useDispatch } from "react-redux";

import { Button, Card, CardBody, Row } from "reactstrap";

import { setValue } from "./Forms/Edit/actions";

import UserBankAccountDetail from "containers/User/components/bankAccount/detail";

function BankAccountDetail({ user }) {
	const dispatch = useDispatch();

	const showModalEdit = () => {
		dispatch(setValue({ item: user.bankAccount, showModalEdit: true }));
	};

	if (!user) return null;

	return (
		<Card style={{ height: "unset" }}>
			<CardBody>
				<Row className="justify-content-between align-items-center">
					<div className="card__title">
						<h5 className="bold-text">Conta bancária</h5>
					</div>
					<div>
						<Button
							color={user.bankAccount ? "secondary" : "primary"}
							onClick={showModalEdit}
						>
							{user.bankAccount ? "Editar" : "Cadastrar"}
						</Button>
					</div>
				</Row>

				{user.bankAccount ? (
					<UserBankAccountDetail bankAccount={user.bankAccount} />
				) : (
					<p>Cliente não possui conta bancária cadastrada</p>
				)}
			</CardBody>
		</Card>
	);
}

export default BankAccountDetail;
