import * as api from "services/api";
import { getErrors } from "helpers/error";

export const EDIT_BANK_ACCOUNT = "[BANK_ACCOUNT EDIT] EDIT_BANK_ACCOUNT";
export const EDIT_BANK_ACCOUNT_ERROR =
	"[BANK_ACCOUNT EDIT] EDIT_BANK_ACCOUNT_ERROR";
export const EDIT_BANK_ACCOUNT_SUCCESS =
	"[BANK_ACCOUNT EDIT] EDIT_BANK_ACCOUNT_SUCCESS";

export const SET_VALUE = "[BANK_ACCOUNT EDIT] SET_VALUE";
export const CLEAR_VALUES = "[BANK_ACCOUNT EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createBankAccount(data, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_BANK_ACCOUNT
		});

		try {
			const { login } = getState();

			data.bank = data.bank.code;
			data.type = data.type.value;

			let response = await api.sendPost(`/bankAccount`, data, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: EDIT_BANK_ACCOUNT_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				callback && callback(getErrors(result.errors));

				dispatch({
					type: EDIT_BANK_ACCOUNT_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			callback && callback();

			return dispatch({
				type: EDIT_BANK_ACCOUNT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function editBankAccount(id, data, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_BANK_ACCOUNT
		});

		try {
			const { login } = getState();

			data.bank = data.bank.code;
			data.type = data.type.value;

			let response = await api.sendPut(`/bankAccount/${id}`, data, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: EDIT_BANK_ACCOUNT_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				callback && callback(getErrors(result.errors));

				dispatch({
					type: EDIT_BANK_ACCOUNT_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			callback && callback();

			return dispatch({
				type: EDIT_BANK_ACCOUNT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
