import ls from "Localization";

const validate = values => {
	const errors = {};

	if (!values.firstName) {
		errors.firstName = ls.requiredField;
	}

	if (!values.lastName) {
		errors.lastName = ls.requiredField;
	}

	if (!values.document) {
		errors.document = ls.requiredField;
	}

	if (!values.email) {
		errors.email = ls.requiredField;
	}

	return errors;
};

export default validate;
