import * as api from "services/api";
import { getErrors } from "helpers/error";

import LogRocket from "logrocket";
import ls from "Localization";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN = "LOGIN";

export const PASSWORD_RECOVERY = "PASSWORD_RECOVERY";
export const PASSWORD_RECOVERY_SUCCESS = "PASSWORD_RECOVERY_SUCCESS";
export const PASSWORD_RECOVERY_FAILED = "PASSWORD_RECOVERY_FAILED";

export const SET_USER_DATA = "[USER] SET DATA";
export const GET_USER_DATA = "[USER] GET DATA";
export const GET_USER_DATA_ERROR = "[USER] GET DATA ERROR";

export const REMOVE_USER_DATA = "[USER] REMOVE DATA";
export const USER_LOGGED_OUT = "[USER] LOGGED OUT";
export const SET_ERROR = "[USER] SER_ERROR";

export const SET_VALUE = "[LOGIN] SET_VALUE";
export const CLEAR_VALUES = "[LOGIN] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function submitRecoverPassword(email) {
	return async dispatch => {
		dispatch({ type: PASSWORD_RECOVERY });

		const response = await api.sendPost(`/account/${email}/recovery`);

		const json = await response.json();

		if (response.ok && json.isValid) {
			dispatch({ type: PASSWORD_RECOVERY_SUCCESS });

			dispatch(
				setValue({
					username: email,
					message: ls.newPasswordSentForEmail
				})
			);
		} else {
			if (json.errors.default.includes("User is null")) {
				dispatch({
					type: PASSWORD_RECOVERY_FAILED,
					payload: { recoverEmail: "E-mail inválido" }
				});
			} else {
				let error = "Ocorreu um erro";

				dispatch({
					type: PASSWORD_RECOVERY_FAILED,
					payload: { recoverEmail: error }
				});
			}
		}
	};
}

export function handleHttpResponse(response) {
	if (response.ok) return response.json();

	if (response.status === 401) {
		throw new Error("Http Response is Unauthorized");
	}

	throw new Error("Http Response is not ok");
}

export function handleHttpError(error) {
	return dispatch => {
		if (error.message === "Http Response is Unauthorized") {
			dispatch(logoutUser());
		}

		dispatch({
			type: "ERROR"
		});
	};
}

export function logoutUser() {
	return {
		type: USER_LOGGED_OUT
	};
}

export function refreshToken(dispatch, getState) {
	let {
		login: { token, user }
	} = getState();

	try {
		let refreshTokenPromise = api
			.sendPost("/token/refresh?token=" + token + "&userId=" + user._id)
			.then(handleHttpResponse)
			.then(json => {
				if (json.isValid && json.model) {
					let token = json.model;

					dispatch({
						type: LOGIN_SUCCESS,
						payload: token
					});

					dispatch({
						type: REFRESH_TOKEN_SUCCESS
					});
					Promise.resolve(token);
				} else {
					const error = {
						username: json.errors.includes("Invalid email.")
							? "Invalid email."
							: json.errors.includes("Account disabled.")
							? "Account disabled."
							: null,
						password: json.errors.includes("Invalid password.")
							? "Invalid password."
							: null
					};

					dispatch({
						type: REFRESH_TOKEN_ERROR,
						payload: error
					});

					Promise.reject(error);
				}
			})
			.catch(err => {
				dispatch(handleHttpError(err));
			});

		dispatch({
			type: REFRESH_TOKEN,
			payload: refreshTokenPromise
		});

		return refreshTokenPromise;
	} catch (error) {
		dispatch({
			type: REFRESH_TOKEN_ERROR,
			payload: {
				error: "Connection error"
			}
		});
	}
}

export function getUserData(callback) {
	return async (dispatch, getState) => {
		const { token } = getState().login;

		dispatch({
			type: GET_USER_DATA
		});

		try {
			let response = await api.sendGet("/user/me", {
				Authorization: "Bearer " + token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: SET_USER_DATA,
					payload: result.model
				});

				if (process.env.REACT_APP_LOG_ROCKET)
					LogRocket.identify(result.model.id, {
						name: result.model.fullName,
						email: result.model.email
					});

				callback && callback();
			} else {
				dispatch({
					type: GET_USER_DATA_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_USER_DATA_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function submitLogin(data, callback) {
	return async dispatch => {
		dispatch({
			type: LOGIN
		});

		const { email, password } = data;

		try {
			let response = await api.sendPost("/token", {
				email,
				password
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: LOGIN_SUCCESS,
					payload: result.model
				});

				dispatch(getUserData(callback));
			} else {
				dispatch({
					type: LOGIN_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: LOGIN_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
