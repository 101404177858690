import React, { useRef } from "react";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Row
} from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import dayjs from "dayjs";
import { toStringCurrency, toStringPercentage } from "helpers/string";
import ls from "Localization";
import couponStatus from "models/couponStatus";
import couponOrigin from "models/couponOrigin";
import discountType from "models/discountType";
import * as editCouponActions from "../Edit/actions";
import EditCoupon from "../Edit";
import * as deleteCouponActions from "../Delete/actions";
import DeleteCoupon from "../Delete";
import { MdDelete, MdEdit } from "react-icons/md";

let fetchDataDebounced = null;

function Report() {
	const dispatch = useDispatch();

	const state = useSelector(s => s.coupon);
	const table = useRef();

	const handleEdit = item => {
		dispatch(editCouponActions.setValue({ item, showEditForm: true }));
	};

	const handleDelete = item => {
		dispatch(deleteCouponActions.setValue({ item, showDeleteForm: true }));
	};

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];
		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getCoupons(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	const load = () => handleFetchData(table.current?.state, null);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title="Cupons"
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						// onRowClicked={row => history.push(`/coupon/` + row._id)}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={[
							{
								Header: "Data criação",
								id: "createdAt",
								accessor: c =>
									c.createdAt || c.dateCreated
										? dayjs(c.createdAt || c.dateCreated).format(
												ls.dateTimeFormat
										  )
										: "",
								width: 130
							},
							{
								Header: "Código",
								id: "code",
								accessor: c => c.code,
								width: 120
							},
							{
								Header: "Usuário",
								id: "user",
								accessor: c =>
									c.user ? `${c.user.firstName} ${c.user.lastName}` : "Nenhum"
							},
							{
								Header: "Desconto",
								id: "value",
								accessor: c =>
									c.discountType === discountType.ABSOLUTE
										? toStringCurrency(c.value)
										: toStringPercentage(c.value, 100, 1),
								width: 100
							},
							{
								Header: "Comissão",
								id: "commission",
								accessor: c =>
									[null, undefined].includes(c.commission)
										? "Não definido"
										: c.commissionType === discountType.ABSOLUTE
										? toStringCurrency(c.commission)
										: toStringPercentage(c.commission, 100, 1),
								width: 100
							},
							{
								Header: "Expiração",
								id: "expireDate",
								accessor: c =>
									c.expireDate
										? dayjs(c.expireDate).format(ls.dateTimeFormat)
										: "Não expira",
								width: 130
							},
							{
								Header: "Tipo de desconto",
								id: "discountType",
								accessor: c => ls[discountType[c.discountType]],
								width: 120,
								Filter: ({ filter, onChange }) => (
									<select
										onChange={event => onChange(event.target.value)}
										style={{ width: "100%" }}
										value={filter ? filter.value : "all"}
									>
										<option value="">Todos</option>
										{Object.keys(discountType).map(c => (
											<option key={c} value={c}>
												{ls[discountType[c]]}
											</option>
										))}
									</select>
								)
							},
							{
								Header: "Origem",
								id: "origin",
								accessor: c => ls[couponOrigin[c.origin]],
								width: 100,
								Filter: ({ filter, onChange }) => (
									<select
										onChange={event => onChange(event.target.value)}
										style={{ width: "100%" }}
										value={filter ? filter.value : "all"}
									>
										<option value="">Todas</option>
										{Object.keys(couponOrigin).map(c => (
											<option key={c} value={c}>
												{ls[couponOrigin[c]]}
											</option>
										))}
									</select>
								)
							},
							{
								Header: "Status",
								id: "status",
								accessor: c => ls[couponStatus[c.status]],
								width: 100,
								Filter: ({ filter, onChange }) => (
									<select
										onChange={event => onChange(event.target.value)}
										style={{ width: "100%" }}
										value={filter ? filter.value : "all"}
									>
										<option value="">Todos</option>
										{Object.keys(couponStatus).map(c => (
											<option key={c} value={c}>
												{ls[couponStatus[c]]}
											</option>
										))}
									</select>
								)
							},
							{
								Header: "Ações",
								id: "actions",
								sortable: false,
								filterable: false,
								accessor: c => {
									return (
										<div
											style={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
												flex: 1
											}}
										>
											<ButtonToolbar>
												<ButtonGroup className="btn-group--icons" dir="ltr">
													<Button onClick={() => handleEdit(c)}>
														<MdEdit style={{ margin: 0 }} />
													</Button>

													<Button
														color="cancel"
														onClick={() => handleDelete(c)}
													>
														<MdDelete style={{ margin: 0 }} />
													</Button>
												</ButtonGroup>
											</ButtonToolbar>
										</div>
									);
								},
								width: 130
							}
						]}
					/>
				</Col>
				<EditCoupon load={load} />
				<DeleteCoupon load={load} />
			</Row>
		</Container>
	);
}

export default Report;
