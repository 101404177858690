import React, { useRef } from "react";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Row
} from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import dayjs from "dayjs";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import { MdCheck } from "react-icons/md";
import commissionStatus from "models/commissionStatus";
import StatusPayment from "./Status";
import { isInRole } from "helpers/auth";
import userRole from "models/userRole";

let fetchDataDebounced = null;

function Report() {
	const dispatch = useDispatch();

	const state = useSelector(s => s.payment);
	const { user } = useSelector(s => s.login);

	const table = useRef();

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];
		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getPayments(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	const load = () => handleFetchData(table.current?.state, null);

	const handleChange = data => {
		dispatch(
			actions.setValue({
				statusPayment: {
					form: true,
					user_id: data.user._id,
					paymentDate: data.paymentDate
				}
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						title="Pagamentos"
						tableRef={table}
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						defaultSorted={[
							{
								id: "paymentDate",
								desc: true
							}
						]}
						columns={[
							{
								Header: "Data Pagamento",
								id: "paymentDate",
								accessor: c =>
									c.paymentDate
										? dayjs(c.paymentDate).format(ls.dateFormat)
										: "Não definido",
								width: 150
							},
							{
								Header: "Usuário",
								id: "user.fullName",
								accessor: c => c.user.fullName
							},
							{
								Header: "Valor total dos pedidos(R$)",
								id: "totalOrder",
								accessor: c => toStringCurrency(c.totalOrder - c.discount),
								format: c => {
									return Number(c.totalOrder - c.discount);
								},
								width: 120,
								filterable: false
							},
							{
								Header: "Quantidade de pedidos",
								id: "count",
								accessor: c => c.count,
								width: 100,
								filterable: false
							},
							{
								Header: "Comissão",
								id: "total",
								accessor: c => toStringCurrency(c.total),
								format: c => {
									return Number(c.total);
								},
								filterable: false,
								width: 100
							},
							{
								Header: "Status",
								id: "status",
								accessor: c => ls[commissionStatus[c.status]],
								width: 100,
								Filter: ({ filter, onChange }) => (
									<select
										onChange={event => onChange(event.target.value)}
										style={{ width: "100%" }}
										value={filter ? filter.value : "all"}
									>
										<option value="">Todos</option>
										{Object.keys(commissionStatus).map(c => (
											<option key={c} value={c}>
												{ls[commissionStatus[c]]}
											</option>
										))}
									</select>
								)
							},
							{
								Header: "Ações",
								id: "actions",
								width: 80,
								Cell: ({ original, index }) => (
									<div
										style={{
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
											flex: 1
										}}
									>
										<ButtonToolbar>
											<ButtonGroup className="btn-group--icons" dir="ltr">
												{original.status ? null : (
													<Button onClick={() => handleChange(original)}>
														<MdCheck style={{ margin: 0 }} />
													</Button>
												)}
											</ButtonGroup>
										</ButtonToolbar>
									</div>
								),
								filterable: false
							}
						].filter(c => {
							return (
								isInRole(user, [userRole.ADMIN]) ||
								(c.id !== "user.fullName" && c.id !== "actions")
							);
						})}
					/>
				</Col>
			</Row>
			<StatusPayment load={load} />
		</Container>
	);
}

export default Report;
