import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { useEffect } from "react";

function TooltipComponent({ target, title }) {
	const [tooltipOpen, setTooltipOpen] = useState(false);

	const toggle = () => setTooltipOpen(!tooltipOpen);

	return (
		<Tooltip
			placeholder="top"
			isOpen={tooltipOpen}
			target={target}
			toggle={toggle}
			delay={{ show: 300, hide: 0 }}
			trigger="hover" //Required for solution of modal
		>
			{title}
		</Tooltip>
	);
}

export default TooltipComponent;
