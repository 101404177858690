import React, { useRef } from "react";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Row
} from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import * as deleteActions from "../Delete/actions";
import * as editActions from "../Edit/actions";
import _ from "lodash";
import dayjs from "dayjs";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import commissionStatus from "models/commissionStatus";
import { isInRole } from "helpers/auth";
import userRole from "models/userRole";
import { MdDelete, MdEdit } from "react-icons/md";
import Delete from "../Delete";
import Edit from "../Edit";

let fetchDataDebounced = null;

function Report() {
	const dispatch = useDispatch();

	const state = useSelector(s => s.commission);
	const { user } = useSelector(s => s.login);

	const table = useRef();

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];
		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getCommissions(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	const handleDelete = data => {
		dispatch(
			deleteActions.setValue({
				showDeleteForm: data
			})
		);
	};

	const handleEdit = data => {
		dispatch(
			editActions.setValue({
				showEditForm: data
			})
		);
	};

	const load = () => handleFetchData(table.current?.state, null);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						title="Comissões"
						tableRef={table}
						data={state.items}
						pages={state.pages}
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id]) === filter.value
						}
						//onRowClicked={row => history.push(`/commission/` + row._id)}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={[
							{
								Header: "Data criação",
								id: "createdAt",
								accessor: c =>
									dayjs(c.createdAt).format(ls.dateTimeFormatWithoutSeconds),
								width: 120
							},
							{
								Header: "Dia de pagamento",
								id: "paymentDate",
								accessor: c => dayjs(c.paymentDate).format(ls.dateFormat),
								width: 160
							},
							{
								Header: "Nome",
								id: "user.fullName",
								accessor: "user.fullName"
							},
							{
								Header: "Pedido",
								accessor: "orderId",
								width: isInRole(user, [userRole.ADMIN]) ? 100 : undefined
							},
							{
								Header: "Parcela",
								id: "number",
								filterable: false,
								accessor: c => `${c.number} de ${c.amount}`,
								width: 100
							},
							{
								Header: "Valor do pedido",
								id: "order.subTotal",
								filterable: false,
								accessor: c =>
									toStringCurrency(c.order.subTotal - c.order.discount),
								format: c => {
									return Number(c.order.subTotal - c.order.discount);
								},
								width: 140
							},
							{
								Header: "Valor da parcela",
								id: "order.installmentValue",
								filterable: false,
								accessor: c =>
									toStringCurrency(
										(c.order.subTotal - c.order.discount) / c.amount
									),
								format: c => {
									return Number(
										(c.order.subTotal - c.order.discount) / c.amount
									);
								},
								width: 140
							},
							{
								Header: "Comissão",
								id: "value",
								accessor: c => toStringCurrency(c.value),
								format: c => {
									return Number(c.value);
								},
								width: 100
							},

							{
								Header: "Status",
								id: "status",
								accessor: c => ls[commissionStatus[c.status]],
								width: 100,
								Filter: ({ filter, onChange }) => (
									<select
										onChange={event => onChange(event.target.value)}
										style={{ width: "100%" }}
										value={filter ? filter.value : "all"}
									>
										<option value="">Todos</option>
										{Object.keys(commissionStatus).map(c => (
											<option key={c} value={c}>
												{ls[commissionStatus[c]]}
											</option>
										))}
									</select>
								)
							},
							{
								Header: "Ações",
								width: 80,
								sortable: false,
								filterable: false,
								id: "actions",
								Cell: props => (
									<ButtonToolbar>
										<ButtonGroup className="btn-group--icons" dir="ltr">
											<Button onClick={() => handleEdit(props.original)}>
												<MdEdit />
											</Button>
											<Button
												color="cancel"
												onClick={() => handleDelete(props.original)}
											>
												<MdDelete />
											</Button>
										</ButtonGroup>
									</ButtonToolbar>
								)
							}
						].filter(c => {
							return (
								isInRole(user, [userRole.ADMIN]) ||
								(c.id !== "user.fullName" && c.id !== "actions")
							);
						})}
					/>
				</Col>
			</Row>
			<Delete load={load} />
			<Edit load={load} />
		</Container>
	);
}

export default Report;
