import React from "react";
import { Route, Switch } from "react-router-dom";
import Report from "./containers/Report";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/payment" component={Report} />
			</Switch>
		</React.Fragment>
	);
};

export default routes;
