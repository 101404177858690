import { isInRole } from "helpers/auth";
import { toStringCurrency } from "helpers/string";
import ArrowDownwardIcon from "mdi-react/ArrowDownwardIcon";
import userRole from "models/userRole";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import Panel from "../../../shared/components/Panel";

function MyCoupon({ items }) {
	const { user } = useSelector(s => s.login);

	return (
		<Panel
			md={12}
			lg={6}
			xl={isInRole(user, [userRole.ADMIN]) ? 6 : 4}
			title="Últimas comissões"
			subhead=""
			panelClass="dashboard__booking-reservations-panel"
		>
			<div className="dashboard__booking-reservations">
				<Table responsive className="table dashboard__occupancy-table">
					<thead>
						<tr>
							{isInRole(user, [userRole.ADMIN]) ? <th>Afiliado</th> : null}
							<th>Pedido</th>
							<th>Valor</th>
							<th>Comissão</th>
						</tr>
					</thead>
					<tbody>
						{[...(items || [])].map((c, i) => (
							<tr key={i}>
								{isInRole(user, [userRole.ADMIN]) ? (
									<td>
										<Link to={`/user/${c.user ? c.user._id : ""}`}>
											{c.user ? `${c.user.firstName} ${c.user.lastName}` : ""}
										</Link>
									</td>
								) : null}
								<td>#{c.order.orderId}</td>
								<td>{toStringCurrency(c.order.subTotal - c.order.discount)}</td>
								<td>{toStringCurrency(c.value)}</td>
							</tr>
						))}
					</tbody>
				</Table>
				<Link to="/commission" className="dashboard__booking-reservations-link">
					Ver mais{" "}
					<ArrowDownwardIcon className="dashboard__booking-reservations-link-icon" />
				</Link>
			</div>
		</Panel>
	);
}

export default MyCoupon;
