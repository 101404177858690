import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
	getCommissionForUser,
	setValue,
	changeCommissionStatus,
	changeUserStatus
} from "../actions";
import { SubmissionError } from "redux-form";
import StatusPaymentForm from "./StatusPaymentForm";

function StatusPayment({ styles }) {
	const dispatch = useDispatch();

	const { statusPayment } = useSelector(state => state.userDetails);

	const { form, user_id, paymentDate, load } = statusPayment || {};

	if (!(user_id && paymentDate)) return null;

	const onSubmit = data => {
		// console.log(data)
		dispatch(
			changeCommissionStatus(user_id, paymentDate, err => {
				if (err) {
					throw new SubmissionError(err);
				} else {
					dispatch(getCommissionForUser(user_id));
					closeModal();
				}
			})
		);
	};

	const closeModal = () => {
		dispatch(setValue({ statusPayment: { form: false } }));
		load();
	};

	const initialValues = {
		id: user_id,
		paymentDate
	};

	return (
		<Modal
			toggle={closeModal}
			className="theme-light ltr-support login-modal-form"
			isOpen={form}
			size="md"
		>
			<ModalHeader>Atualizar Pagamento</ModalHeader>
			<ModalBody>
				<StatusPaymentForm
					styles={styles}
					closeModal={closeModal}
					initialValues={initialValues}
					onSubmit={onSubmit}
				/>
			</ModalBody>
		</Modal>
	);
}

export default StatusPayment;

StatusPayment.propTypes = {
	onChange: PropTypes.func,
	styles: PropTypes.object
};
