import { getErrors } from "../../../../helpers/error";
import * as api from "../../../../services/api";

export const CREATE_COUPON = "[USER_COUPON CREATE] CREATE_COUPON";
export const CREATE_COUPON_ERROR = "[USER_COUPON CREATE] CREATE_COUPON_ERROR";
export const CREATE_COUPON_SUCCESS =
	"[USER_COUPON CREATE] CREATE_COUPON_SUCCESS";

export const SET_VALUE = "[USER_COUPON CREATE] SET_VALUE";
export const CLEAR_VALUES = "[USER_COUPON CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createCoupon(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_COUPON
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/coupon`, model, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: CREATE_COUPON_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				callback && callback(getErrors(result.errors));

				dispatch({
					type: CREATE_COUPON_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			callback && callback();

			return dispatch({
				type: CREATE_COUPON_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
