import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const GET_COMMISSION = "[COMMISSION REPORT] GET_COMMISSION";
export const GET_COMMISSION_ERROR = "[COMMISSION REPORT] GET_COMMISSION_ERROR";
export const GET_COMMISSION_SUCCESS =
	"[COMMISSION REPORT] GET_COMMISSION_SUCCESS";

export const GET_LAST_COMMISSION = "[COMMISSION REPORT] GET_LAST_COMMISSION";
export const GET_LAST_COMMISSION_ERROR =
	"[COMMISSION REPORT] GET_LAST_COMMISSION_ERROR";
export const GET_LAST_COMMISSION_SUCCESS =
	"[COMMISSION REPORT] GET_LAST_COMMISSION_SUCCESS";

export const SET_VALUE = "[COMMISSION REPORT] SET_VALUE";
export const CLEAR_VALUES = "[COMMISSION REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getCommissions(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_COMMISSION
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/commission?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_COMMISSION_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_COMMISSION_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_COMMISSION_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getLastCommissions(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_LAST_COMMISSION
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/commission/last`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_LAST_COMMISSION_SUCCESS,
					payload: result.model
				});

				if (result.params)
					dispatch(
						setValue({
							pages: Math.ceil(result.params.count / take),
							...result.params
						})
					);

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_LAST_COMMISSION_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_LAST_COMMISSION_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const GET_COMMISSION_INFO = "[COMMISSION REPORT] GET_COMMISSION_INFO";
export const GET_COMMISSION_INFO_ERROR =
	"[COMMISSION REPORT] GET_COMMISSION_INFO_ERROR";
export const GET_COMMISSION_INFO_SUCCESS =
	"[COMMISSION REPORT] GET_COMMISSION_INFO_SUCCESS";

export function getCommissionInfo(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_COMMISSION_INFO
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/commission/info`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_COMMISSION_INFO_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_COMMISSION_INFO_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_COMMISSION_INFO_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export const GET_TOP_AFFILIATES = "[COMMISSION REPORT] GET_TOP_AFFILIATES";
export const GET_TOP_AFFILIATES_ERROR =
	"[COMMISSION REPORT] GET_TOP_AFFILIATES_ERROR";
export const GET_TOP_AFFILIATES_SUCCESS =
	"[COMMISSION REPORT] GET_TOP_AFFILIATES_SUCCESS";

export function getTopAffiliates(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_TOP_AFFILIATES
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/commission/affiliates/top`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_TOP_AFFILIATES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_TOP_AFFILIATES_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_TOP_AFFILIATES_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
