import * as actions from "./actions";

const initialState = {
	items: [],
	filter: [],
	pages: null,
	count: 0,
	info: null,
	topAffiliates: [],
	last: [],
	loading: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_COMMISSION: {
			return {
				...state,
				loading: { getAll: true }
			};
		}
		case actions.GET_COMMISSION_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: { getAll: false }
			};
		}
		case actions.GET_COMMISSION_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { getAll: false }
			};
		}
		case actions.GET_LAST_COMMISSION: {
			return {
				...state,
				loading: { last: true }
			};
		}
		case actions.GET_LAST_COMMISSION_SUCCESS: {
			return {
				...state,
				last: payload ? payload : [],
				loading: { last: false }
			};
		}
		case actions.GET_LAST_COMMISSION_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { last: false }
			};
		}
		case actions.GET_COMMISSION_INFO: {
			return {
				...state,
				loading: { info: true }
			};
		}
		case actions.GET_COMMISSION_INFO_SUCCESS: {
			return {
				...state,
				info: payload ? payload : [],
				loading: { info: false }
			};
		}
		case actions.GET_COMMISSION_INFO_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { info: false }
			};
		}
		case actions.GET_TOP_AFFILIATES: {
			return {
				...state,
				loading: { top: true }
			};
		}
		case actions.GET_TOP_AFFILIATES_SUCCESS: {
			return {
				...state,
				topAffiliates: payload ? payload : [],
				loading: { top: false }
			};
		}
		case actions.GET_TOP_AFFILIATES_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { top: false }
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
