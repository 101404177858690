import TooltipComponent from "components/TooltipComponent";
import { getCouponForUser } from "containers/Coupon/containers/Detail/actions";
import EditCoupon from "containers/Coupon/containers/Edit";
import { isInRole } from "helpers/auth";
import EditIcon from "mdi-react/EditIcon";
import userRole from "models/userRole";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../../shared/components/Panel";
import * as editCouponActions from "../../Coupon/containers/Edit/actions";

function MyCoupon() {
	const dispatch = useDispatch();

	const [linkCopy, setLinkCopy] = useState("");
	const [isCopy, setIsCopy] = useState(false);

	const { item } = useSelector(c => c.couponDetails);
	const { user } = useSelector(s => s.login);

	const load = () => {
		dispatch(
			getCouponForUser(err => {
				if (err) console.log(err);
			})
		);
	};

	useEffect(() => {
		load();
	}, []);

	useEffect(() => {
		setLinkCopy(
			`${process.env.REACT_APP_APPLICATION_URL}?r=${item && item.code}`
		);
	}, [load]);

	const handleCopy = () => {
		const Obj = linkCopy;
		navigator.clipboard.writeText(Obj);
		setIsCopy(true);
	};

	const handleEditCoupon = () => {
		dispatch(editCouponActions.setValue({ showEditForm: true, item: item }));
	};

	return (
		<Panel
			md={12}
			lg={6}
			xl={isInRole(user, [userRole.ADMIN]) ? 6 : 4}
			title="Seu cupom"
			subhead="Toque no link para copiar"
			panelClass="dashboard__booking-reservations-panel"
		>
			<div className="dashboard__booking-reservations">
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between"
					}}
				>
					<p
						className="dashboard__booking-reservations-number"
						style={{ fontSize: "calc(16px + 6 * ((100vw - 320px) / 680))" }}
					>
						{(item && item.code) || "Sem cupom"}
					</p>
					<button
						className="panel__btn btn-24"
						type="button"
						onClick={handleEditCoupon}
					>
						<EditIcon />
					</button>
				</div>

				<span onClick={handleCopy} className="span-link" id="copy">
					{linkCopy}
				</span>
				<TooltipComponent
					target="copy"
					title={
						isCopy ? "Copiado Para a Área de Transferência" : "Copiar Link"
					}
				/>

				<EditCoupon load={load} />
			</div>
		</Panel>
	);
}

export default MyCoupon;
