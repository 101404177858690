import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import MyCoupon from "./components/MyCoupon";
import TotalCommission from "./components/TotalCommission";
import TotalCouponUse from "./components/TotalCouponUse";
import LastCommissions from "./components/LastCommissions";
import TopAffiliates from "./components/TopAffiliates";
import TotalOrderValue from "./components/TotalOrderValue";
// import TotalDiscount from "./components/TotalDiscount";
// import TotalPeople from "./components/TotalPeople";

import {
	getCommissionInfo,
	getLastCommissions
} from "containers/Commission/containers/Report/actions";

function Dashboard() {
	const dispatch = useDispatch();

	const { info, last } = useSelector(s => s.commission);

	useEffect(() => {
		dispatch(getCommissionInfo());
		dispatch(getLastCommissions());
	}, []);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<h3 className="page-title">Dashboard</h3>
				</Col>
			</Row>
			<Row>
				<TotalOrderValue
					total={(info || {}).totalOrder - (info || {}).discount}
				/>
				<TotalCommission total={(info || {}).total} />
				{/* <TotalDiscount /> */}
				<TotalCouponUse total={(info || {}).count} />
				{/* <TotalPeople /> */}
			</Row>
			<Row>
				<MyCoupon />
				<LastCommissions items={last} />
				<TopAffiliates />
			</Row>
		</Container>
	);
}

export default Dashboard;
