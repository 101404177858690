import React from "react";
import EditForm from "./EditForm";
import PropTypes from "prop-types";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { setValue, editCommission } from "./actions";
import { getCommissions } from "../Report/actions";
import { SubmissionError } from "redux-form";
import ls from "Localization";
import dayjs from "dayjs";
import commissionStatus from "models/commissionStatus";
import { toStringCurrency } from "helpers/string";
import validate from "./validate";
import customDateFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customDateFormat);

function Edit({ styles, load }) {
	const dispatch = useDispatch();

	const { showEditForm } = useSelector(s => s.commissionEdit);
	const d = showEditForm;
	if (!d) return null;

	const onSubmit = data => {
		const errors = validate(data);

		if (Object.keys(errors).length > 0) {
			throw new SubmissionError(errors);
		}

		const model = {
			paymentDate: dayjs(data.paymentDate).toISOString(),
			status: data.status.value,
			value: data.value
				.replace(",", ".")
				.match(/[\d\.-]/g)
				.join("")
		};

		dispatch(
			editCommission(d._id, model, err => {
				if (err) {
					throw new SubmissionError(err);
				} else {
					closeModal();
					load && load();
				}
			})
		);
	};

	const closeModal = () => {
		dispatch(
			setValue({
				showEditForm: false
			})
		);
	};

	const statusValues = Object.keys(commissionStatus).map(c => ({
		value: c,
		label: ls[commissionStatus[c]]
	}));

	const initialValues = {
		status: statusValues.find(c => c.value === String(d.status)),
		paymentDate: dayjs(d.paymentDate).toDate(),
		value: toStringCurrency(d.value)
	};

	return (
		<Modal
			toggle={closeModal}
			className="theme-light ltr-support login-modal-form"
			isOpen={Boolean(showEditForm)}
		>
			<ModalHeader>Editar Comissão</ModalHeader>
			<ModalBody>
				<EditForm
					styles={styles}
					closeModal={closeModal}
					initialValues={initialValues}
					statusValues={statusValues}
					onSubmit={onSubmit}
				/>
			</ModalBody>
		</Modal>
	);
}

export default Edit;

Edit.propTypes = {
	onChange: PropTypes.func,
	styles: PropTypes.object
};
