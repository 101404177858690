import React from "react";
import { Route, Switch } from "react-router-dom";
import Report from "./containers/Report";
import Detail from "./containers/Detail";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/commission" component={Report} />
				<Route exact path="/commission/:id" component={Detail} />
			</Switch>
		</React.Fragment>
	);
};

export default routes;
